import * as React from "react"
import Header from "../components/header-mentor"
import Footer from "../components/footer"
import imgProfile from "../images/steve-cv.png"
import logoGreen from "../images/logo-green.png"
import ContactForm from "../components/contact-form"
import GDPRConsent from "../components/gdpr-consent"
import Seo from "../components/seo"
import PackagekOffer from "../components/package-block/steve"
import DiscountBanners from "../components/discount-banners"

function coachingCurriculum() {
  return (
    <div className="text-left px-10 py-0">

      <b>Macro:</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Economic Data and trading around them
        </li>
        <li>
          Tracking Economic Data
        </li>
        <li>
          Understanding Economic Data
        </li>
        <li>
          Learning which types of Economic Data usually move the markets
        </li>
      </ul>

      <b>Candlesticks:</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Reading and Understanding Candlesticks on a chart
        </li>
        <li>
          Bullish Candlesticks / Candlestick formations
        </li>
        <li>
          Bearish Candlesticks / Candlestick formations
        </li>
        <li>
          Reversal Candlesticks / Candlestick formations
        </li>
      </ul>

      <b>Principles of Technical Analysis:</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          What is a Trend, learning to identify it
        </li>
        <li>
          Moving averages and how to use them in trading
        </li>
        <li>
          Fibonacci Retracements and Fibonacci Extensions
        </li>
        <li>
          Basic Technical Analysis Patterns
        </li>
        <li>
          RSI and other momentum indicators
        </li>
        <li>
          Breakouts, Stops, Retracements
        </li>
        <li>
          Trend trading – Range Trading and Counter-Trend trading techniques
        </li>
        <li>
          A very basic understanding of Elliott Waves and how to utilize it in combination with the basic technical analysis tools for confirmation.
          <br />(For in depth E.W. knowledge and theory Grega Horvat is your man)
        </li>
      </ul>

      <b>Risk Management:</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          What is Risk Management
        </li>
        <li>
          How to implement Risk / Reward techniques in trading
        </li>
        <li>
          Learning to use stops and limit orders
        </li>
      </ul>

    </div>
  )
}

const IndexPage = () => {
  const mentor = {
    name: "Steve Voulgaridis",
    firstName: "Steve",
    twitter: "@vulgi",
    headerText: "Are you interested in Macroeconomic theory and the study of fundamentals, to build a top – down, long term view of the markets?",
    videoUrlCode: "JCmvd-UAKK4",
  }
  return (
    <>
      <Seo title='Trader Coach Steve' />
      <GDPRConsent />
      <Header props={mentor} />
      <main>
        <>
          {<DiscountBanners mentor='steve' />}
          <div className="bg-gray-900 text-white pt-8" id="bio">
            <div className="lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="flex items-end">
                <div className="">
                  <img
                    className="inline w-40 md:w-60 lg:w-80"
                    src={imgProfile}
                    alt={'Steve'}
                  />
                </div>
                <div className="px-2 md:pb-6">
                  <span className="pb-3 block text-green-400 text-3xl font-bold">{mentor.name}</span>
                  <span className="block text-white md:text-2xl font-bold">ForexAnalytix Co-Founder & C.O.O.</span>
                </div>
              </div>

              <div className="text-justify px-6 pt-6">
                Steve Voulgaridis is a dedicated trader with a wealth of experience in the financial markets. With dual degrees in Business Administration and Finance, he has been navigating the complex world of finance since 2005, transforming his passion into a successful career path.
                <br /><br />
                Journey
                <br /><br />
                His trading journey began as an individual investor, managing personal and family funds, and quickly evolved into a role as a consultant and trader for a private fund. This hands-on experience, combined with his academic background, has equipped him with a deep understanding of the markets from a top-down, long-term perspective.
                <br /><br />
                His Approach
                <br /><br />
                Steve believes in the power of education to transform trading from a gamble into a disciplined, analytical pursuit. His approach is grounded in macroeconomic theory and the study of fundamentals, allowing him to guide traders towards understanding the bigger market picture. For daily market analysis, he relies on a toolkit that includes Classical Chart Patterns, Candlestick Patterns, Fibonacci retracements, and the basics of Elliott Wave Theory. This blend of long-term strategy and precise, technical analysis ensures that he is well-prepared to navigate the markets confidently.
              </div>

              <div className="text-center">
                <img
                  className="inline h-60 w-60 lg:w-80 lg:h-80"
                  src={logoGreen}
                  alt="logo"
                />

              </div>
            </div>

          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-50 text-gray-900 py-8">
            <div className="block text-center text-xl font-bold px-2 md:text-xl">
              <div className="text-green-400 text-2xl">BOOK NOW</div>
              Buy 3 sessions of 1 hour and get $150 discount<br />
              <span className="text-green-400">OR</span><br />
              Buy 1 session of 1 hour and get 40% discount<br />
              <span className="text-xl md:text-lg font-normal">* applies one time only for your first 1 hour session</span>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-900 text-white">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-8 px-4 sm:px-6 sm:pt-16 lg:pb-8 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold py-8 text-green-400">
                Coaching Curriculum
              </div>
              {coachingCurriculum()}
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <PackagekOffer />

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-12 px-4 sm:px-6 sm:pt-16 lg:pb-6 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold mb-6 mt-6 text-white text-center">
                Get 40% off for your first 1 hour session using the coupon: <span className="text-red-600 text-4xl">40%OFF</span>
              </div>
              <div className="text-xl font-bold mt-6 text-center">
                <u>Billed at $120</u>.
              </div>
              <div className="font-normal text-lg">
                Standard price for 1 hour session $200.
              </div>

              <div className="mt-4 flex justify-center" id="booknow">
                <div className="inline-flex">
                  <a
                    href="https://TraderCoach.as.me/?appointmentType=56187550"
                    target="_blank" rel="noopener" rel="noreferrer"
                    // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
                    className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
                  >
                    Buy 1 Session Now!
                  </a>
                </div>
              </div>

              <div className="font-normal text-lg mt-8">
                * Click on the "Redeem Coupon or Package" button to enter the coupon code.
              </div>
              <div className="mb-4"></div>
            </div>
          </div>

          <div className="relative max-w-7xl mx-auto pt-10 pb-12 px-0 lg:px-8 h-full" id="booknow">
            <section className="bg-gray-100 overflow-hidden" id="steve">
              <iframe
                onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"
                src="https://app.acuityscheduling.com/schedule.php?owner=30981395&appointmentType=56187550" title="Schedule Appointment" width="100%" height="1000" frameBorder="0"></iframe>
                <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </section>
          </div>

          <ContactForm props={{ name: mentor.firstName, twitter: mentor.twitter }} />

        </>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
